import React, { useEffect } from 'react';
import {
  IAdvertiserOrAgency,
  IOpenOrder,
  ISelectedInvoiceOrOrderItem,
} from '../../redux/overview/OverviewModels';
import { Box, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import OrderDetails from './Invoices/OrderDetails';

import { useVirtualizer } from '@tanstack/react-virtual';

interface IOrderProps {
  advertiser: IAdvertiserOrAgency;
  selectedItems: ISelectedInvoiceOrOrderItem[];
  handleSelection: (advertiser: IAdvertiserOrAgency, invoice: IOpenOrder) => void;
}

const Order = ({ advertiser, selectedItems, handleSelection }: IOrderProps) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const items = advertiser.openOrders || [];
  const [collapseStatus, setCollapseStatus] = React.useState<any>({});

  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,

    estimateSize: (i) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return collapseStatus?.[items[i].orderNumber] ? 700 : 140;
    },
  });

  useEffect(() => {
    virtualizer.measure();
  }, [collapseStatus]);

  const handleCollapse = (orderId: any, status: boolean) => {
    setCollapseStatus({ ...collapseStatus, [orderId]: status });
  };

  return (
    <Box sx={{ maxHeight: '400px', overflowY: 'auto', p: 5 }} ref={parentRef}>
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {virtualizer.getVirtualItems().map((virtualRow) => {
          const order: IOpenOrder = items[virtualRow.index];
          const selectedOrder = selectedItems.find((selectedOrder) => {
            return selectedOrder?.advertiserOrAgencyId === advertiser?.advertiserOrAgencyId;
          });
          const isSelected = (selectedOrder?.items || []).find(
            (item) => item?.number + item?.dueDate === order?.orderNumber + order?.dueDate,
          );
          return (
            <Box
              key={virtualRow.index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
              py={4}
              borderTop={`${theme.spacing(0.5)} dashed ${grey[200]}`}
            >
              <OrderDetails
                selectable
                isSelected={!!isSelected}
                onSelect={(order, checked) => handleSelection(advertiser, order)}
                order={order}
                collapseStatus={!!collapseStatus?.[order.orderNumber]}
                onCollapse={handleCollapse}
              />
            </Box>
          );
        })}
      </div>
    </Box>
  );
};
export default Order;
